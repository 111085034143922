exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-bugs-tsx": () => import("./../../../src/pages/bugs.tsx" /* webpackChunkName: "component---src-pages-bugs-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-releases-tsx": () => import("./../../../src/pages/releases.tsx" /* webpackChunkName: "component---src-pages-releases-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

